import { graphql } from "gatsby";
import Image from "gatsby-image";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import Breadcrumb from "../modules/breadcrumb";
import Layout from "../modules/layout";
import SEO from "../modules/seo";

const TeamDetail = ({ data: { member }, pageContext }) => {
  const { name, settings, breadcrumbs, showBreadcrumbNavigation, node_locale} = pageContext;
  const siteSettings = settings;

  return (
    <Layout siteSettings={siteSettings}>
      <SEO
        title={`${siteSettings.siteName} - ${name}`}
        description={siteSettings.siteDescription}
        author={siteSettings.siteAuthor}
      />
      <Breadcrumb
        visible={showBreadcrumbNavigation}
        breadcrumbs={breadcrumbs}
        locale={node_locale}
      ></Breadcrumb>
      <Container fluid as="section" className="teamDetail">
        <Row>
          <Col sm={3} lg={4}>
            <Image fluid={member.photo.imagePortrait.fluid} className="photo" />
          </Col>
          <Col sm={9} lg={8}>
            <h2 className="name">{member.name}</h2>
            <h4 className="position">{member.jobTitle}</h4>
            <p className="d-none d-md-block biography">
              {member.biography && (
                <ReactMarkdown>{member.biography.biography}</ReactMarkdown>
              )}
            </p>
          </Col>
          <Col className="d-md-none biography">
            {member.biography && (
              <ReactMarkdown>{member.biography.biography}</ReactMarkdown>
            )}
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export const pageQuery = graphql`
  query($contentful_id: String!, $node_locale: String!) {
    member: contentfulTeamMember(
      contentful_id: { eq: $contentful_id }
      node_locale: { eq: $node_locale }
    ) {
      name
      jobTitle
      email
      telephone
      biography {
        biography
      }
      photo {
        imagePortrait {
          fluid {
            base64
            aspectRatio
            src
            srcSet
            sizes
            srcWebp
            srcSetWebp
          }
        }
      }
    }
  }
`;

export default TeamDetail;
